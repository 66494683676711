<template>
  <v-img v-if="icon.src" :src="icon.src" v-bind="$attrs" />
</template>

<script>
  import DirectLinkIcon from '@/services/classes/DirectLinkIcon.js'

  export default {
    name: 'DirectLinkIcon',
    inheritAttrs: false,
    props: {
      icon: DirectLinkIcon
    }
  }
</script>
