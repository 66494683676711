<template>
  <div>
    <template v-if="isMobile">
      <a v-if="src" :href="src" target="_blank">
        {{ shortLink }}
        <v-icon small color="primary" class="text-decoration-none"> mdi-open-in-new </v-icon>
      </a>
    </template>
    <v-tooltip v-else top>
      <template #activator="{ on, attrs }">
        <a v-if="src" :href="src" target="_blank" v-bind="attrs" v-on="on">
          {{ shortLink }}
          <v-icon small color="primary" class="text-decoration-none"> mdi-open-in-new </v-icon>
        </a>
      </template>
      <span> {{ src }} </span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'SrcOuterLink',
    props: {
      src: {
        type: String,
        default: ''
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      shortLink() {
        if (this.src.length < 50) {
          return this.src
        }

        const fromStart = this.src.substr(0, 20)
        const fromEnd = this.src.substr(-20)

        return `${fromStart}...${fromEnd}`
      }
    }
  }
</script>
